<template>
  <div>
    <div
      class="appNavbarMobile__container"
      style="
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        padding: 7px 15px;
        left: 0;
        right: 0;
        height: 60px;
        width: 100%;
        background: #ffffff;
        z-index: 9999;
      "
      :style="[
        [].includes($route.name)
          ? { background: 'var(--main)' }
          : { background: '#ffffff' },
      ]"
      @closeMenu="navbarMobileToggle"
    >
      <div style="display: flex; width: 35px">
        <div class="navbar_button_requests">
          <RouterLink
            replace
            v-if="
              [
                'children-product_details_wishlist',
                'children-checkout_wishlist',
              ].includes($route.name)
            "
            to="/children/wishlist"
            class="backButton"
          >
            <svg
              style="width: 25px; height: 25px"
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </RouterLink>
          <RouterLink
            v-else-if="
              [
                'children-product_details_shop',
                'children-checkout_shop',
              ].includes($route.name)
            "
            to="/children/shop"
            class="backButton"
            replace
          >
            <svg
              style="width: 25px; height: 25px"
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </RouterLink>
          <RouterLink
            v-else-if="
              [
                'children-product_details_goal',
                'children-checkout_goal',
              ].includes($route.name)
            "
            to="/children/goals"
            class="backButton"
            replace
          >
            <svg
              style="width: 25px; height: 25px"
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </RouterLink>
          <RouterLink
            v-else-if="
              [
                'children-coins_game_wishlist',
                'children-checkout_game_wishlist',
              ].includes($route.name)
            "
            to="/children/product_details_wishlist"
            class="backButton"
            replace
          >
            <svg
              style="width: 25px; height: 25px"
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </RouterLink>
          <RouterLink
            v-else-if="
              [
                'children-coins_game_shop',
                'children-checkout_game_shop',
              ].includes($route.name)
            "
            to="/children/product_details_shop"
            class="backButton"
            replace
          >
            <svg
              style="width: 25px; height: 25px"
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </RouterLink>
          <RouterLink
            v-else-if="
              [
                'children-coins_game_goal',
                'children-checkout_game_goal',
              ].includes($route.name)
            "
            to="/children/product_details_goal"
            class="backButton"
            replace
          >
            <svg
              style="width: 25px; height: 25px"
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </RouterLink>
          <img
            v-else
            src="../assets/images/requests.svg"
            alt=""
            width="25"
            style="display: none"
            @click="commingSoon"
          />
        </div>
      </div>

      <img
        v-if="[].includes($route.name)"
        src="../assets/images/logo.png"
        class="nav-logo"
        alt="Logo"
      />
      <img
        v-else
        src="../assets/images/logo-color.png"
        class="nav-logo"
        alt=""
      />

      <span
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        "
      >
        <RouterLink
          replace
          to="/children/profile"
          class="appNavbar__listLink navbar_button_requests"
          style="font-size: 0px"
        >
          <img
            v-if="user"
            :src="resolveImage(user.avatarLink)"
            style="
              border-radius: 100px;
              object-fit: cover;
              width: 35px;
              height: 35px;
              background: white;
              padding: 2px;
            "
            alt="Guardian avatar"
          />
        </RouterLink>
      </span>
    </div>
  </div>
</template>

<script>
  import config from "../config";
  import { mapGetters, mapActions } from "vuex";

  export default {
    data() {
      return {
        isKeyboardVisible: false,
        initialHeight: window.innerHeight,
        dropdown: false,
      };
    },
    computed: {
      ...mapGetters(["childName", "user"]),
    },
    methods: {
      ...mapActions(["TOAST"]),
      navbarMobileToggle() {
        this.dropdown = !this.dropdown;
      },
      commingSoon() {
        this.TOAST("Coming soon!");
      },
      resolveImage(src) {
        return config.HOST + src;
      },
    },
  };
</script>

<style lang="scss">
  .appNavbarMobile {
    width: 100%;

    &__container {
      padding: 15px;
      display: flex;
      justify-content: space-between;

      &__menu {
        width: 50px;
        height: 50px;
        background: #ffffff;
        border-radius: 100%;
        background-image: url("../assets/images/navbarMenu.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.13);
        cursor: pointer;
      }
    }

    .dropdown {
      position: absolute;
      height: 100vh;
      margin: -20px;
      z-index: 20;
    }
  }

  @keyframes enter-in {
    from {
      transform: translateY(-100vh);
    }
    to {
      transform: translateY(0);
    }
  }

  .navbar_button_requests {
    border: none;
    background: transparent;

    &:active {
      opacity: 0.5;
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  .nav-logo {
    height: 35px;
  }

  @-webkit-keyframes nav-secondary {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-1em);
      transform: translateY(-1em);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  @keyframes nav-secondary {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-1em);
      transform: translateY(-1em);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .nav-logo {
    height: 35px;
  }
</style>
