var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"appNavbarMobile__container",staticStyle:{"display":"flex","align-items":"center","position":"fixed","top":"0","padding":"7px 15px","left":"0","right":"0","height":"60px","width":"100%","background":"#ffffff","z-index":"9999"},style:([
      [].includes(_vm.$route.name)
        ? { background: 'var(--main)' }
        : { background: '#ffffff' },
    ]),on:{"closeMenu":_vm.navbarMobileToggle}},[_c('div',{staticStyle:{"display":"flex","width":"35px"}},[_c('div',{staticClass:"navbar_button_requests"},[(
            [
              'children-product_details_wishlist',
              'children-checkout_wishlist',
            ].includes(_vm.$route.name)
          )?_c('RouterLink',{staticClass:"backButton",attrs:{"replace":"","to":"/children/wishlist"}},[_c('svg',{staticClass:"h-5 w-5",staticStyle:{"width":"25px","height":"25px"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z","clip-rule":"evenodd"}})])]):(
            [
              'children-product_details_shop',
              'children-checkout_shop',
            ].includes(_vm.$route.name)
          )?_c('RouterLink',{staticClass:"backButton",attrs:{"to":"/children/shop","replace":""}},[_c('svg',{staticClass:"h-5 w-5",staticStyle:{"width":"25px","height":"25px"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z","clip-rule":"evenodd"}})])]):(
            [
              'children-product_details_goal',
              'children-checkout_goal',
            ].includes(_vm.$route.name)
          )?_c('RouterLink',{staticClass:"backButton",attrs:{"to":"/children/goals","replace":""}},[_c('svg',{staticClass:"h-5 w-5",staticStyle:{"width":"25px","height":"25px"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z","clip-rule":"evenodd"}})])]):(
            [
              'children-coins_game_wishlist',
              'children-checkout_game_wishlist',
            ].includes(_vm.$route.name)
          )?_c('RouterLink',{staticClass:"backButton",attrs:{"to":"/children/product_details_wishlist","replace":""}},[_c('svg',{staticClass:"h-5 w-5",staticStyle:{"width":"25px","height":"25px"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z","clip-rule":"evenodd"}})])]):(
            [
              'children-coins_game_shop',
              'children-checkout_game_shop',
            ].includes(_vm.$route.name)
          )?_c('RouterLink',{staticClass:"backButton",attrs:{"to":"/children/product_details_shop","replace":""}},[_c('svg',{staticClass:"h-5 w-5",staticStyle:{"width":"25px","height":"25px"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z","clip-rule":"evenodd"}})])]):(
            [
              'children-coins_game_goal',
              'children-checkout_game_goal',
            ].includes(_vm.$route.name)
          )?_c('RouterLink',{staticClass:"backButton",attrs:{"to":"/children/product_details_goal","replace":""}},[_c('svg',{staticClass:"h-5 w-5",staticStyle:{"width":"25px","height":"25px"},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z","clip-rule":"evenodd"}})])]):_c('img',{staticStyle:{"display":"none"},attrs:{"src":require("../assets/images/requests.svg"),"alt":"","width":"25"},on:{"click":_vm.commingSoon}})],1)]),([].includes(_vm.$route.name))?_c('img',{staticClass:"nav-logo",attrs:{"src":require("../assets/images/logo.png"),"alt":"Logo"}}):_c('img',{staticClass:"nav-logo",attrs:{"src":require("../assets/images/logo-color.png"),"alt":""}}),_c('span',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"center","align-items":"center"}},[_c('RouterLink',{staticClass:"appNavbar__listLink navbar_button_requests",staticStyle:{"font-size":"0px"},attrs:{"replace":"","to":"/children/profile"}},[(_vm.user)?_c('img',{staticStyle:{"border-radius":"100px","object-fit":"cover","width":"35px","height":"35px","background":"white","padding":"2px"},attrs:{"src":_vm.resolveImage(_vm.user.avatarLink),"alt":"Guardian avatar"}}):_vm._e()])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }