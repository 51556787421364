<template>
  <div class="children-layout">
    <TopNavigationBar />
      <router-view style="flex-grow: 1; margin-top: 75px; margin-bottom: 75px;" />
    <BottomNavigationBar />
  </div>
</template>

<script>
  import TopNavigationBar from "../pages/children/components/TopNavigationBar.vue";
  import BottomNavigationBar from "../pages/children/components/BottomNavigationBar.vue";

  export default {
    components: {
      TopNavigationBar,
      BottomNavigationBar,
    },
  };
</script>

<style scoped>
  /* .children-layout {
    background: red;
  } */
</style>
