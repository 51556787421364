<template>
  <div>
    <nav
      v-if="![].includes($route.name)"
      class="tabs"
      
      style="display: block; height: 90px"
      :class="{ 'keyboard-visible': isKeyboardVisible }"
      
    >
      <ul class="-primary">
        <li v-for="page in pages" class="tab" :key="page.id" :class="{ tab__active: page.link === $route.path }" style="position: relative">
          <RouterLink
            replace
            style="display: flex; flex-direction: column"
            :to="page.link"
            class="appNavbar__listLink"
            
          >
            <img :src="navicon(page.icon)" width="32" :alt="page.icon" />

            <span style="color:#01A4C0; font-size: em; margin-top: 5px">{{ page.name }}</span>
          </RouterLink>
          <!-- <div v-if="page.link === $route.path" class="point" /> -->
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
  import config from "../config";

  export default {
    data() {
      return {
        isKeyboardVisible: false,
        initialHeight: window.innerHeight,
        pages: [
          { id: 1, icon: "home", name: "Dashboard", link: "/children" },
          {
            id: 2,
            icon: "missions",
            name: "Missions",
            link: "/children/missions",
          },
          {
            id: 3,
            icon: "wishlist",
            name: "Wishlist",
            link: "/children/wishlist",
          },
          {
            id: 4,
            icon: "goals",
            name: "Goals",
            link: "/children/goals",
          },
          { id: 6, icon: "shop", name: "Store", link: "/children/shop" },
        ],
      };
    },
    methods: {
      navicon(img) {
        return require(`../assets/images/navbar-icons/${img}.svg`);
      },
      resolveImage(src) {
        return config.HOST + src;
      },
      handleResize() {
        const height =
          document.documentElement.clientHeight || window.innerHeight;

          if(height < this.initialHeight) {
            this.isKeyboardVisible = true;
          } else {
            this.isKeyboardVisible = false;
          }
      },
    },
    // mounted() {
    //   window.addEventListener("resize", this.handleResize);
    // },
    // beforeDestroy() {
    //   window.removeEventListener("resize", this.handleResize);
    // },
  };
</script>

<style lang="scss">
  .point {
    background-color: white;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 5px;
  }

  @keyframes enter-in {
    from {
      transform: translateY(-100vh);
    }
    to {
      transform: translateY(0);
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  .tabs {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 9999;
    color: #01A4C0;
    // opacity: 0.5;
    
    // &__active {
    //   opacity: 1.0 !important;
    // }
  }

  .tab{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 9999;
    color: #01A4C0;
    opacity: 0.3;
    
    &__active {
      opacity: 1.0 !important;
      // box-shadow: 0 0 15px rgba(0, 0, 0, 0.1) inset;
    }
  }

  .tabs:not(.--jsfied) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .tabs .--hidden {
    display: none;
  }

  .tabs a,
  .tabs button {
    width: 100%;
    height: 100%;
    display: block;
    font-size: 1em;
    line-height: 1.2;
    text-align: center;
    color: #ffffff;
  }

  .activeTab {
    background-color: #ce4ba9;
  }

  .tabs .-primary {
    display: -webkit-box;
    display: flex;
  }

  .tabs ul {
    list-style-type: none;
  }

  .tabs .-primary > li {
    -webkit-box-flex: 1;
    flex-grow: 1;
    //background-color: #fff;
  }

  // .tabs .-primary > li + li {
  //   border-left: 1px solid #36d5f270;
  // }

  .tabs ul li a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tabs .-primary > li > a,
  .tabs .-primary > li > button {
    white-space: nowrap;
    padding: 0.7em 0.6em;
    //box-shadow: inset 0 -0.2em 0 #b61a3e;
  }

  .tabs .-primary .-more {
    background-color: #ffffff;
  }

  .tabs .-primary .-more > button span {
    display: inline-block;
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
  }

  .tabs.--show-secondary .-primary .-more > button span {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .tabs .-secondary {
    max-width: 100%;
    min-width: 10em;
    display: none;
    position: absolute;
    top: -197px;
    z-index: 9999;
    right: 0;
    box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
    -webkit-animation: nav-secondary 0.2s;
    animation: nav-secondary 0.2s;
  }

  @-webkit-keyframes nav-secondary {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-1em);
      transform: translateY(-1em);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  @keyframes nav-secondary {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-1em);
      transform: translateY(-1em);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .keyboard-visible {
    display: none !important;
  }
</style>
